html.a11y-resize-font-200, html.a11y-resize-font-200 body {
	font-size: 25px !important;
}
html.a11y-resize-font-190, html.a11y-resize-font-190 body {
	font-size: 24px !important;
}
html.a11y-resize-font-180, html.a11y-resize-font-180 body {
	font-size: 23px !important;
}
html.a11y-resize-font-170, html.a11y-resize-font-170 body {
	font-size: 22px !important;
}
html.a11y-resize-font-160, html.a11y-resize-font-160 body {
	font-size: 21px !important;
}
html.a11y-resize-font-150, html.a11y-resize-font-150 body {
	font-size: 20px !important;
}
html.a11y-resize-font-140, html.a11y-resize-font-140 body {
	font-size: 19px !important;
}
html.a11y-resize-font-130, html.a11y-resize-font-130 body {
	font-size: 18px !important;
}
html.a11y-resize-font-120, html.a11y-resize-font-120 body {
	font-size: 17px !important;
}
