

html.a11y-links-underline {
	/* Temporarily checking selectors
a[onclick] , a[onclick] abbr, a[onclick] acronym, a[onclick] b,
a[onclick] basefont, a[onclick] big, a[onclick] br, a[onclick] code,
a[onclick] div, a[onclick] em, a[onclick] font, a[onclick] h1,
a[onclick] h2, a[onclick] h3, a[onclick] h4, a[onclick] h5, a[onclick]
h6, a[onclick] i, a[onclick] kbd, a[onclick] small, a[onclick] span,
a[onclick] strong, a[onclick] tt, a[onclick] u, a[onclick] var,
a[onclick]:active , a[onclick]:active abbr, a[onclick]:active acronym,
a[onclick]:active b, a[onclick]:active basefont, a[onclick]:active
big, a[onclick]:active br, a[onclick]:active code, a[onclick]:active
div, a[onclick]:active em, a[onclick]:active font, a[onclick]:active
h1, a[onclick]:active h2, a[onclick]:active h3, a[onclick]:active h4,
a[onclick]:active h5, a[onclick]:active h6, a[onclick]:active i,
a[onclick]:active kbd, a[onclick]:active small, a[onclick]:active
span, a[onclick]:active strong, a[onclick]:active tt,
a[onclick]:active u, a[onclick]:active var {
  text-decoration: underline !important;
}
a:link , a:link abbr, a:link acronym, a:link b, a:link basefont,
a:link big, a:link br, a:link code, a:link div, a:link em, a:link
font, a:link h1, a:link h2, a:link h3, a:link h4, a:link h5, a:link
h6, a:link i, a:link kbd, a:link small, a:link span, a:link strong,
a:link tt, a:link u, a:link var, a:link:active , a:link:active abbr,
a:link:active acronym, a:link:active b, a:link:active basefont,
a:link:active big, a:link:active br, a:link:active code, a:link:active
div, a:link:active em, a:link:active font, a:link:active h1,
a:link:active h2, a:link:active h3, a:link:active h4, a:link:active
h5, a:link:active h6, a:link:active i, a:link:active kbd,
a:link:active small, a:link:active span, a:link:active strong,
a:link:active tt, a:link:active u, a:link:active var, a:visited ,
a:visited abbr, a:visited acronym, a:visited b, a:visited basefont,
a:visited big, a:visited br, a:visited code, a:visited div, a:visited
em, a:visited font, a:visited h1, a:visited h2, a:visited h3,
a:visited h4, a:visited h5, a:visited h6, a:visited i, a:visited kbd,
a:visited small, a:visited span, a:visited strong, a:visited tt,
a:visited u, a:visited var, a:visited:active , a:visited:active abbr,
a:visited:active acronym, a:visited:active b, a:visited:active
basefont, a:visited:active big, a:visited:active br, a:visited:active
code, a:visited:active div, a:visited:active em, a:visited:active
font, a:visited:active h1, a:visited:active h2, a:visited:active h3,
a:visited:active h4, a:visited:active h5, a:visited:active h6,
a:visited:active i, a:visited:active kbd, a:visited:active small,
a:visited:active span, a:visited:active strong, a:visited:active tt,
a:visited:active u, a:visited:active var {
  text-decoration: underline !important;
}*/
}
html.a11y-links-underline a,
html.a11y-links-underline a * {
	text-decoration: underline !important;
}
