html.a11y-negative-contrast a,
html.a11y-negative-contrast abbr,
html.a11y-negative-contrast acronym,
html.a11y-negative-contrast address,
html.a11y-negative-contrast article,
html.a11y-negative-contrast aside,
html.a11y-negative-contrast b,
html.a11y-negative-contrast basefont,
html.a11y-negative-contrast bdi,
html.a11y-negative-contrast big,
html.a11y-negative-contrast blink,
html.a11y-negative-contrast blockquote,
html.a11y-negative-contrast body,
html.a11y-negative-contrast canvas,
html.a11y-negative-contrast caption,
html.a11y-negative-contrast center,
html.a11y-negative-contrast cite,
html.a11y-negative-contrast code,
html.a11y-negative-contrast col,
html.a11y-negative-contrast colgroup,
html.a11y-negative-contrast command,
html.a11y-negative-contrast dd,
html.a11y-negative-contrast del,
html.a11y-negative-contrast details,
html.a11y-negative-contrast dfn,
html.a11y-negative-contrast dir,
html.a11y-negative-contrast div:not(.background-overlay),
html.a11y-negative-contrast dl,
html.a11y-negative-contrast dt,
html.a11y-negative-contrast em,
html.a11y-negative-contrast embed,
html.a11y-negative-contrast fieldset,
html.a11y-negative-contrast figcaption,
html.a11y-negative-contrast figure,
html.a11y-negative-contrast font,
html.a11y-negative-contrast footer,
html.a11y-negative-contrast form,
html.a11y-negative-contrast h1,
html.a11y-negative-contrast h1 a,
html.a11y-negative-contrast h1 a b,
html.a11y-negative-contrast h1 abbr,
html.a11y-negative-contrast h1 b,
html.a11y-negative-contrast h1 center,
html.a11y-negative-contrast h1 em,
html.a11y-negative-contrast h1 i,
html.a11y-negative-contrast h1 span,
html.a11y-negative-contrast h1 strong,
html.a11y-negative-contrast h2,
html.a11y-negative-contrast h2 a,
html.a11y-negative-contrast h2 a b,
html.a11y-negative-contrast h2 abbr,
html.a11y-negative-contrast h2 b,
html.a11y-negative-contrast h2 center,
html.a11y-negative-contrast h2 em,
html.a11y-negative-contrast h2 i,
html.a11y-negative-contrast h2 span,
html.a11y-negative-contrast h2 strong,
html.a11y-negative-contrast h3,
html.a11y-negative-contrast h3 a,
html.a11y-negative-contrast h3 a b,
html.a11y-negative-contrast h3 abbr,
html.a11y-negative-contrast h3 b,
html.a11y-negative-contrast h3 center,
html.a11y-negative-contrast h3 em,
html.a11y-negative-contrast h3 i,
html.a11y-negative-contrast h3 span,
html.a11y-negative-contrast h3 strong,
html.a11y-negative-contrast h4,
html.a11y-negative-contrast h4 a,
html.a11y-negative-contrast h4 a b,
html.a11y-negative-contrast h4 abbr,
html.a11y-negative-contrast h4 b,
html.a11y-negative-contrast h4 center,
html.a11y-negative-contrast h4 em,
html.a11y-negative-contrast h4 i,
html.a11y-negative-contrast h4 span,
html.a11y-negative-contrast h4 strong,
html.a11y-negative-contrast h5,
html.a11y-negative-contrast h5 a,
html.a11y-negative-contrast h5 a b,
html.a11y-negative-contrast h5 abbr,
html.a11y-negative-contrast h5 b,
html.a11y-negative-contrast h5 center,
html.a11y-negative-contrast h5 em,
html.a11y-negative-contrast h5 i,
html.a11y-negative-contrast h5 span,
html.a11y-negative-contrast h5 strong,
html.a11y-negative-contrast h6,
html.a11y-negative-contrast h6 a,
html.a11y-negative-contrast h6 a b,
html.a11y-negative-contrast h6 abbr,
html.a11y-negative-contrast h6 b,
html.a11y-negative-contrast h6 center,
html.a11y-negative-contrast h6 em,
html.a11y-negative-contrast h6 i,
html.a11y-negative-contrast h6 span,
html.a11y-negative-contrast h6 strong,
html.a11y-negative-contrast header,
html.a11y-negative-contrast hgroup,
html.a11y-negative-contrast html,
html.a11y-negative-contrast i,
html.a11y-negative-contrast iframe,
html.a11y-negative-contrast input,
html.a11y-negative-contrast ins,
html.a11y-negative-contrast kbd,
html.a11y-negative-contrast label,
html.a11y-negative-contrast legend,
html.a11y-negative-contrast li,
html.a11y-negative-contrast listing,
html.a11y-negative-contrast main,
html.a11y-negative-contrast mark,
html.a11y-negative-contrast marquee,
html.a11y-negative-contrast menu,
html.a11y-negative-contrast meter,
html.a11y-negative-contrast multicol,
html.a11y-negative-contrast nav:not(#a11y-toolbar),
html.a11y-negative-contrast nobr,
html.a11y-negative-contrast object,
html.a11y-negative-contrast ol,
html.a11y-negative-contrast option,
html.a11y-negative-contrast output,
html.a11y-negative-contrast p,
html.a11y-negative-contrast plaintext,
html.a11y-negative-contrast pre,
html.a11y-negative-contrast progress,
html.a11y-negative-contrast q,
html.a11y-negative-contrast rb,
html.a11y-negative-contrast rp,
html.a11y-negative-contrast rt,
html.a11y-negative-contrast ruby,
html.a11y-negative-contrast s,
html.a11y-negative-contrast samp,
html.a11y-negative-contrast section,
html.a11y-negative-contrast small,
html.a11y-negative-contrast span,
html.a11y-negative-contrast strike,
html.a11y-negative-contrast strong,
html.a11y-negative-contrast sub,
html.a11y-negative-contrast summary,
html.a11y-negative-contrast sup,
html.a11y-negative-contrast svg,
html.a11y-negative-contrast table,
html.a11y-negative-contrast tbody,
html.a11y-negative-contrast td,
html.a11y-negative-contrast text,
html.a11y-negative-contrast textarea,
html.a11y-negative-contrast th,
html.a11y-negative-contrast thead,
html.a11y-negative-contrast time,
html.a11y-negative-contrast tr,
html.a11y-negative-contrast tt,
html.a11y-negative-contrast u,
html.a11y-negative-contrast ul,
html.a11y-negative-contrast var,
html.a11y-negative-contrast video,
html.a11y-negative-contrast xmp {
	background-color: black !important;
	color: #ffffff !important;
}
html.a11y-negative-contrast a,
html.a11y-negative-contrast *:not(#a11y-toolbar) a,
html.a11y-negative-contrast b,
html.a11y-negative-contrast *:not(#a11y-toolbar) b,
html.a11y-negative-contrast blockquote,
html.a11y-negative-contrast *:not(#a11y-toolbar) blockquote,
html.a11y-negative-contrast button,
html.a11y-negative-contrast *:not(#a11y-toolbar) button,
html.a11y-negative-contrast canvas,
html.a11y-negative-contrast *:not(#a11y-toolbar) canvas,
html.a11y-negative-contrast caption,
html.a11y-negative-contrast *:not(#a11y-toolbar) caption,
html.a11y-negative-contrast center,
html.a11y-negative-contrast *:not(#a11y-toolbar) center,
html.a11y-negative-contrast cite,
html.a11y-negative-contrast *:not(#a11y-toolbar) cite,
html.a11y-negative-contrast code,
html.a11y-negative-contrast *:not(#a11y-toolbar) code,
html.a11y-negative-contrast col,
html.a11y-negative-contrast *:not(#a11y-toolbar) col,
html.a11y-negative-contrast colgroup,
html.a11y-negative-contrast *:not(#a11y-toolbar) colgroup,
html.a11y-negative-contrast dd,
html.a11y-negative-contrast *:not(#a11y-toolbar) dd,
html.a11y-negative-contrast details,
html.a11y-negative-contrast *:not(#a11y-toolbar) details,
html.a11y-negative-contrast dfn,
html.a11y-negative-contrast *:not(#a11y-toolbar) dfn,
html.a11y-negative-contrast dir,
html.a11y-negative-contrast *:not(#a11y-toolbar) dir,
html.a11y-negative-contrast div:not(.article-top-image):not(.background-overlay):not(.bg-image),
html.a11y-negative-contrast *:not(#a11y-toolbar) div:not(.article-top-image):not(.background-overlay):not(.bg-image),
html.a11y-negative-contrast dl,
html.a11y-negative-contrast *:not(#a11y-toolbar) dl,
html.a11y-negative-contrast dt,
html.a11y-negative-contrast *:not(#a11y-toolbar) dt,
html.a11y-negative-contrast em,
html.a11y-negative-contrast *:not(#a11y-toolbar) em,
html.a11y-negative-contrast embed,
html.a11y-negative-contrast *:not(#a11y-toolbar) embed,
html.a11y-negative-contrast fieldset,
html.a11y-negative-contrast *:not(#a11y-toolbar) fieldset,
html.a11y-negative-contrast figcaption,
html.a11y-negative-contrast *:not(#a11y-toolbar) figcaption,
html.a11y-negative-contrast figure,
html.a11y-negative-contrast *:not(#a11y-toolbar) figure,
html.a11y-negative-contrast font,
html.a11y-negative-contrast *:not(#a11y-toolbar) font,
html.a11y-negative-contrast footer,
html.a11y-negative-contrast *:not(#a11y-toolbar) footer,
html.a11y-negative-contrast form,
html.a11y-negative-contrast *:not(#a11y-toolbar) form,
html.a11y-negative-contrast header,
html.a11y-negative-contrast *:not(#a11y-toolbar) header,
html.a11y-negative-contrast i,
html.a11y-negative-contrast *:not(#a11y-toolbar) i,
html.a11y-negative-contrast iframe,
html.a11y-negative-contrast *:not(#a11y-toolbar) iframe,
html.a11y-negative-contrast img,
html.a11y-negative-contrast *:not(#a11y-toolbar) img,
html.a11y-negative-contrast input,
html.a11y-negative-contrast *:not(#a11y-toolbar) input,
html.a11y-negative-contrast kbd,
html.a11y-negative-contrast *:not(#a11y-toolbar) kbd,
html.a11y-negative-contrast label,
html.a11y-negative-contrast *:not(#a11y-toolbar) label,
html.a11y-negative-contrast legend,
html.a11y-negative-contrast *:not(#a11y-toolbar) legend,
html.a11y-negative-contrast li,
html.a11y-negative-contrast *:not(#a11y-toolbar) li,
html.a11y-negative-contrast mark,
html.a11y-negative-contrast *:not(#a11y-toolbar) mark,
html.a11y-negative-contrast menu,
html.a11y-negative-contrast *:not(#a11y-toolbar) menu,
html.a11y-negative-contrast meter,
html.a11y-negative-contrast *:not(#a11y-toolbar) meter,
html.a11y-negative-contrast nav,
html.a11y-negative-contrast *:not(#a11y-toolbar) nav,
html.a11y-negative-contrast nobr,
html.a11y-negative-contrast *:not(#a11y-toolbar) nobr,
html.a11y-negative-contrast object,
html.a11y-negative-contrast *:not(#a11y-toolbar) object,
html.a11y-negative-contrast ol,
html.a11y-negative-contrast *:not(#a11y-toolbar) ol,
html.a11y-negative-contrast option,
html.a11y-negative-contrast *:not(#a11y-toolbar) option,
html.a11y-negative-contrast pre,
html.a11y-negative-contrast *:not(#a11y-toolbar) pre,
html.a11y-negative-contrast progress,
html.a11y-negative-contrast *:not(#a11y-toolbar) progress,
html.a11y-negative-contrast q,
html.a11y-negative-contrast *:not(#a11y-toolbar) q,
html.a11y-negative-contrast s,
html.a11y-negative-contrast *:not(#a11y-toolbar) s,
html.a11y-negative-contrast section,
html.a11y-negative-contrast *:not(#a11y-toolbar) section,
html.a11y-negative-contrast select,
html.a11y-negative-contrast *:not(#a11y-toolbar) select,
html.a11y-negative-contrast small,
html.a11y-negative-contrast *:not(#a11y-toolbar) small,
html.a11y-negative-contrast span,
html.a11y-negative-contrast *:not(#a11y-toolbar) span,
html.a11y-negative-contrast strike,
html.a11y-negative-contrast *:not(#a11y-toolbar) strike,
html.a11y-negative-contrast strong,
html.a11y-negative-contrast *:not(#a11y-toolbar) strong,
html.a11y-negative-contrast sub,
html.a11y-negative-contrast *:not(#a11y-toolbar) sub,
html.a11y-negative-contrast summary,
html.a11y-negative-contrast *:not(#a11y-toolbar) summary,
html.a11y-negative-contrast sup,
html.a11y-negative-contrast *:not(#a11y-toolbar) sup,
html.a11y-negative-contrast table,
html.a11y-negative-contrast *:not(#a11y-toolbar) table,
html.a11y-negative-contrast td,
html.a11y-negative-contrast *:not(#a11y-toolbar) td,
html.a11y-negative-contrast textarea,
html.a11y-negative-contrast *:not(#a11y-toolbar) textarea,
html.a11y-negative-contrast th,
html.a11y-negative-contrast *:not(#a11y-toolbar) th,
html.a11y-negative-contrast time,
html.a11y-negative-contrast *:not(#a11y-toolbar) time,
html.a11y-negative-contrast tr,
html.a11y-negative-contrast *:not(#a11y-toolbar) tr,
html.a11y-negative-contrast tt,
html.a11y-negative-contrast *:not(#a11y-toolbar) tt,
html.a11y-negative-contrast u,
html.a11y-negative-contrast *:not(#a11y-toolbar) u,
html.a11y-negative-contrast ul,
html.a11y-negative-contrast *:not(#a11y-toolbar) ul,
html.a11y-negative-contrast var,
html.a11y-negative-contrast *:not(#a11y-toolbar) var,
html.a11y-negative-contrast a span,
html.a11y-negative-contrast *:not(#a11y-toolbar) a span,
html.a11y-negative-contrast strong,
html.a11y-negative-contrast *:not(#a11y-toolbar) strong {
	color: yellow !important;
}
html.a11y-negative-contrast button,
html.a11y-negative-contrast *:not(#a11y-toolbar) button,
html.a11y-negative-contrast input,
html.a11y-negative-contrast *:not(#a11y-toolbar) input,
html.a11y-negative-contrast textarea,
html.a11y-negative-contrast *:not(#a11y-toolbar) textarea,
html.a11y-negative-contrast select,
html.a11y-negative-contrast *:not(#a11y-toolbar) select,
html.a11y-negative-contrast table,
html.a11y-negative-contrast *:not(#a11y-toolbar) table,
html.a11y-negative-contrast td,
html.a11y-negative-contrast *:not(#a11y-toolbar) td,
html.a11y-negative-contrast th,
html.a11y-negative-contrast *:not(#a11y-toolbar) th,
html.a11y-negative-contrast tr,
html.a11y-negative-contrast *:not(#a11y-toolbar) tr,
html.a11y-negative-contrast tt,
html.a11y-negative-contrast *:not(#a11y-toolbar) tt {
	border: 1px solid #ffffff !important;
}
html.a11y-negative-contrast div#gmap,
html.a11y-negative-contrast div#gmap * {
	background: initial !important;
}
