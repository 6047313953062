#a11y-toolbar {
	position: fixed;
	font-size: 1rem;
	line-height: 1.4;
	z-index: 9999;
}
#a11y-toolbar.a11y-toolbar-right {
	right: -11rem;
	-webkit-transition: right 750ms cubic-bezier(0.23, 1, 0.32, 1);
	-moz-transition: right 750ms cubic-bezier(0.23, 1, 0.32, 1);
	-o-transition: right 750ms cubic-bezier(0.23, 1, 0.32, 1);
	transition: right 750ms cubic-bezier(0.23, 1, 0.32, 1);
	top: 20vh;
}
#a11y-toolbar.a11y-toolbar-right.a11y-toolbar-open {
	right: 0;
}
#a11y-toolbar.a11y-toolbar-right .a11y-toolbar-toggle {
	right: 11rem;
}
#a11y-toolbar.a11y-toolbar-left {
	left: -11rem;
	-webkit-transition: left 750ms cubic-bezier(0.23, 1, 0.32, 1);
	-moz-transition: left 750ms cubic-bezier(0.23, 1, 0.32, 1);
	-o-transition: left 750ms cubic-bezier(0.23, 1, 0.32, 1);
	transition: left 750ms cubic-bezier(0.23, 1, 0.32, 1);
}
#a11y-toolbar.a11y-toolbar-left.a11y-toolbar-open {
	left: 0;
}
#a11y-toolbar.a11y-toolbar-left .a11y-toolbar-toggle {
	left: 11rem;
}
#a11y-toolbar .a11y-toolbar-toggle {
	position: absolute;
}
#a11y-toolbar .a11y-toolbar-toggle a {
	display: inline-block;
	font-size: 2rem;
	line-height: 0;
	padding: 10px;
	border-radius: 8px 0 0 8px;
	box-shadow: 0 0 5px 0px rgba(0,0,0,0.5);
}

#a11y-toolbar .a11y-toolbar-toggle a svg {
	fill:var(--text-color);
}
#a11y-toolbar .a11y-toolbar-toggle a:hover,
#a11y-toolbar .a11y-toolbar-toggle a:focus {
}
#a11y-toolbar .a11y-toolbar-toggle svg {
	max-width: inherit;
}
#a11y-toolbar .a11y-toolbar-overlay {
	font-size: 1rem;
	width: 11rem;
	background-color: #f2f2f2;
	border-bottom-left-radius: 8px;
}
#a11y-toolbar .a11y-toolbar-overlay p.a11y-toolbar-title {
	display: block;
	line-height: 2;
	font-weight: bold;
	padding: 10px 15px 0;
	margin: 0;
}
#a11y-toolbar .a11y-toolbar-overlay.a11y-toolbar-open .a11y-toolbar-toggle a,
#a11y-toolbar .a11y-toolbar-overlay.a11y-toolbar-open .a11y-toolbar-overlay {
	-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
#a11y-toolbar .a11y-toolbar-overlay ul.a11y-toolbar-items {
	list-style: none;
	margin: 0;
	padding: 10px 0;
}
#a11y-toolbar .a11y-toolbar-overlay ul.a11y-toolbar-items.a11y-links {
	border-top: 1px solid;
}
#a11y-toolbar .a11y-toolbar-overlay ul.a11y-toolbar-items li.a11y-toolbar-item a {
	display: block;
	padding: 10px 15px;
	font-size: .8rem;
	line-height: 1;
}
#a11y-toolbar .a11y-toolbar-overlay ul.a11y-toolbar-items li.a11y-toolbar-item a.active {
	font-weight: bold;
}
#a11y-toolbar .a11y-toolbar-overlay ul.a11y-toolbar-items li.a11y-toolbar-item a svg {
	padding-right: 6px;
	display: inline-block;
	width: 1.5em;
	font-style: normal;
	font-weight: normal;
	font-size: inherit;
	line-height: 1;
	text-align: center;
	text-rendering: auto;
	vertical-align: middle;
}
#a11y-toolbar .a11y-toolbar-overlay ul.a11y-toolbar-items li.a11y-toolbar-item a .a11y-toolbar-text {
	vertical-align: middle;
}
html.rtl #a11y-toolbar .a11y-toolbar-overlay ul.a11y-toolbar-items li.a11y-toolbar-item a svg {
	padding-left: 6px;
	padding-right: 0;
}
@media (max-width: 767px) {
	#a11y-toolbar .a11y-toolbar-overlay p.a11y-toolbar-title {
		padding: 7px 12px 0;
	}
	#a11y-toolbar .a11y-toolbar-overlay ul.a11y-toolbar-items {
		padding: 7px 0;
	}
	#a11y-toolbar .a11y-toolbar-overlay ul.a11y-toolbar-items li.a11y-toolbar-item a {
		display: block;
		padding: 7px 12px;
	}
	#a11y-toolbar .a11y-toolbar-toggle a {
		font-size: 1.4rem;
	}
	#a11y-toolbar.a11y-toolbar-right {
		top: 20vh;
	}
	#a11y-toolbar.a11y-toolbar-right .a11y-toolbar-toggle {
		bottom: 34px;
	}
	#a11y-toolbar .a11y-toolbar-overlay {
		border-top-left-radius: 8px;
	}
}
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
