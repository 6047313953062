html.a11y-high-contrast {
  /* This stylesheet based on 0WonB.css generated by Accessibility CSS Generator, (c) Silas S. Brown 2006-2015.  Version 0.9844 */
}

html.a11y-high-contrast .placebo {
  line-height: normal;
}

html.a11y-high-contrast * {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

html.a11y-high-contrast a,
html.a11y-high-contrast abbr,
html.a11y-high-contrast acronym,
html.a11y-high-contrast address,
html.a11y-high-contrast article,
html.a11y-high-contrast aside,
html.a11y-high-contrast b,
html.a11y-high-contrast basefont,
html.a11y-high-contrast bdi,
html.a11y-high-contrast big,
html.a11y-high-contrast blink,
html.a11y-high-contrast blockquote,
html.a11y-high-contrast body,
html.a11y-high-contrast button,
html.a11y-high-contrast canvas,
html.a11y-high-contrast caption,
html.a11y-high-contrast center,
html.a11y-high-contrast cite,
html.a11y-high-contrast code,
html.a11y-high-contrast col,
html.a11y-high-contrast colgroup,
html.a11y-high-contrast command,
html.a11y-high-contrast dd,
html.a11y-high-contrast del,
html.a11y-high-contrast details,
html.a11y-high-contrast dfn,
html.a11y-high-contrast dir,
html.a11y-high-contrast div,
html.a11y-high-contrast dl,
html.a11y-high-contrast dt,
html.a11y-high-contrast em,
html.a11y-high-contrast embed,
html.a11y-high-contrast fieldset,
html.a11y-high-contrast figcaption,
html.a11y-high-contrast figure,
html.a11y-high-contrast font,
html.a11y-high-contrast footer,
html.a11y-high-contrast form,
html.a11y-high-contrast h1,
html.a11y-high-contrast h1 a,
html.a11y-high-contrast h1 a b,
html.a11y-high-contrast h1 abbr,
html.a11y-high-contrast h1 b,
html.a11y-high-contrast h1 center,
html.a11y-high-contrast h1 em,
html.a11y-high-contrast h1 i,
html.a11y-high-contrast h1 span,
html.a11y-high-contrast h1 strong,
html.a11y-high-contrast h2,
html.a11y-high-contrast h2 a,
html.a11y-high-contrast h2 a b,
html.a11y-high-contrast h2 abbr,
html.a11y-high-contrast h2 b,
html.a11y-high-contrast h2 center,
html.a11y-high-contrast h2 em,
html.a11y-high-contrast h2 i,
html.a11y-high-contrast h2 span,
html.a11y-high-contrast h2 strong,
html.a11y-high-contrast h3,
html.a11y-high-contrast h3 a,
html.a11y-high-contrast h3 a b,
html.a11y-high-contrast h3 abbr,
html.a11y-high-contrast h3 b,
html.a11y-high-contrast h3 center,
html.a11y-high-contrast h3 em,
html.a11y-high-contrast h3 i,
html.a11y-high-contrast h3 span,
html.a11y-high-contrast h3 strong,
html.a11y-high-contrast h4,
html.a11y-high-contrast h4 a,
html.a11y-high-contrast h4 a b,
html.a11y-high-contrast h4 abbr,
html.a11y-high-contrast h4 b,
html.a11y-high-contrast h4 center,
html.a11y-high-contrast h4 em,
html.a11y-high-contrast h4 i,
html.a11y-high-contrast h4 span,
html.a11y-high-contrast h4 strong,
html.a11y-high-contrast h5,
html.a11y-high-contrast h5 a,
html.a11y-high-contrast h5 a b,
html.a11y-high-contrast h5 abbr,
html.a11y-high-contrast h5 b,
html.a11y-high-contrast h5 center,
html.a11y-high-contrast h5 em,
html.a11y-high-contrast h5 i,
html.a11y-high-contrast h5 span,
html.a11y-high-contrast h5 strong,
html.a11y-high-contrast h6,
html.a11y-high-contrast h6 a,
html.a11y-high-contrast h6 a b,
html.a11y-high-contrast h6 abbr,
html.a11y-high-contrast h6 b,
html.a11y-high-contrast h6 center,
html.a11y-high-contrast h6 em,
html.a11y-high-contrast h6 i,
html.a11y-high-contrast h6 span,
html.a11y-high-contrast h6 strong,
html.a11y-high-contrast header,
html.a11y-high-contrast hgroup,
html.a11y-high-contrast html,
html.a11y-high-contrast i,
html.a11y-high-contrast iframe,
html.a11y-high-contrast img,
html.a11y-high-contrast input,
html.a11y-high-contrast ins,
html.a11y-high-contrast kbd,
html.a11y-high-contrast label,
html.a11y-high-contrast legend,
html.a11y-high-contrast li,
html.a11y-high-contrast listing,
html.a11y-high-contrast main,
html.a11y-high-contrast mark,
html.a11y-high-contrast marquee,
html.a11y-high-contrast menu,
html.a11y-high-contrast meter,
html.a11y-high-contrast multicol,
html.a11y-high-contrast nav,
html.a11y-high-contrast nobr,
html.a11y-high-contrast object,
html.a11y-high-contrast ol,
html.a11y-high-contrast option,
html.a11y-high-contrast output,
html.a11y-high-contrast p,
html.a11y-high-contrast plaintext,
html.a11y-high-contrast pre,
html.a11y-high-contrast progress,
html.a11y-high-contrast q,
html.a11y-high-contrast rb,
html.a11y-high-contrast rp,
html.a11y-high-contrast rt,
html.a11y-high-contrast ruby,
html.a11y-high-contrast s,
html.a11y-high-contrast samp,
html.a11y-high-contrast section,
html.a11y-high-contrast select,
html.a11y-high-contrast small,
html.a11y-high-contrast span,
html.a11y-high-contrast strike,
html.a11y-high-contrast strong,
html.a11y-high-contrast sub,
html.a11y-high-contrast summary,
html.a11y-high-contrast sup,
html.a11y-high-contrast svg,
html.a11y-high-contrast table,
html.a11y-high-contrast tbody,
html.a11y-high-contrast td,
html.a11y-high-contrast text,
html.a11y-high-contrast textarea,
html.a11y-high-contrast th,
html.a11y-high-contrast thead,
html.a11y-high-contrast time,
html.a11y-high-contrast tr,
html.a11y-high-contrast tt,
html.a11y-high-contrast u,
html.a11y-high-contrast ul,
html.a11y-high-contrast var,
html.a11y-high-contrast video,
html.a11y-high-contrast xmp {
  -moz-appearance: none !important;
  -moz-user-select: text !important;
  -webkit-user-select: text !important;
  text-shadow: none !important;
  user-select: text !important;
}

html.a11y-high-contrast a,
html.a11y-high-contrast abbr,
html.a11y-high-contrast acronym,
html.a11y-high-contrast address,
html.a11y-high-contrast article,
html.a11y-high-contrast aside,
html.a11y-high-contrast b,
html.a11y-high-contrast basefont,
html.a11y-high-contrast bdi,
html.a11y-high-contrast big,
html.a11y-high-contrast blink,
html.a11y-high-contrast blockquote,
html.a11y-high-contrast body,
html.a11y-high-contrast canvas,
html.a11y-high-contrast caption,
html.a11y-high-contrast center,
html.a11y-high-contrast cite,
html.a11y-high-contrast code,
html.a11y-high-contrast col,
html.a11y-high-contrast colgroup,
html.a11y-high-contrast command,
html.a11y-high-contrast dd,
html.a11y-high-contrast del,
html.a11y-high-contrast details,
html.a11y-high-contrast dfn,
html.a11y-high-contrast dir,
html.a11y-high-contrast div:not(.background-overlay),
html.a11y-high-contrast dl,
html.a11y-high-contrast dt,
html.a11y-high-contrast em,
html.a11y-high-contrast embed,
html.a11y-high-contrast fieldset,
html.a11y-high-contrast figcaption,
html.a11y-high-contrast figure,
html.a11y-high-contrast font,
html.a11y-high-contrast footer,
html.a11y-high-contrast form,
html.a11y-high-contrast h1,
html.a11y-high-contrast h1 a,
html.a11y-high-contrast h1 a b,
html.a11y-high-contrast h1 abbr,
html.a11y-high-contrast h1 b,
html.a11y-high-contrast h1 center,
html.a11y-high-contrast h1 em,
html.a11y-high-contrast h1 i,
html.a11y-high-contrast h1 span,
html.a11y-high-contrast h1 strong,
html.a11y-high-contrast h2,
html.a11y-high-contrast h2 a,
html.a11y-high-contrast h2 a b,
html.a11y-high-contrast h2 abbr,
html.a11y-high-contrast h2 b,
html.a11y-high-contrast h2 center,
html.a11y-high-contrast h2 em,
html.a11y-high-contrast h2 i,
html.a11y-high-contrast h2 span,
html.a11y-high-contrast h2 strong,
html.a11y-high-contrast h3,
html.a11y-high-contrast h3 a,
html.a11y-high-contrast h3 a b,
html.a11y-high-contrast h3 abbr,
html.a11y-high-contrast h3 b,
html.a11y-high-contrast h3 center,
html.a11y-high-contrast h3 em,
html.a11y-high-contrast h3 i,
html.a11y-high-contrast h3 span,
html.a11y-high-contrast h3 strong,
html.a11y-high-contrast h4,
html.a11y-high-contrast h4 a,
html.a11y-high-contrast h4 a b,
html.a11y-high-contrast h4 abbr,
html.a11y-high-contrast h4 b,
html.a11y-high-contrast h4 center,
html.a11y-high-contrast h4 em,
html.a11y-high-contrast h4 i,
html.a11y-high-contrast h4 span,
html.a11y-high-contrast h4 strong,
html.a11y-high-contrast h5,
html.a11y-high-contrast h5 a,
html.a11y-high-contrast h5 a b,
html.a11y-high-contrast h5 abbr,
html.a11y-high-contrast h5 b,
html.a11y-high-contrast h5 center,
html.a11y-high-contrast h5 em,
html.a11y-high-contrast h5 i,
html.a11y-high-contrast h5 span,
html.a11y-high-contrast h5 strong,
html.a11y-high-contrast h6,
html.a11y-high-contrast h6 a,
html.a11y-high-contrast h6 a b,
html.a11y-high-contrast h6 abbr,
html.a11y-high-contrast h6 b,
html.a11y-high-contrast h6 center,
html.a11y-high-contrast h6 em,
html.a11y-high-contrast h6 i,
html.a11y-high-contrast h6 span,
html.a11y-high-contrast h6 strong,
html.a11y-high-contrast header,
html.a11y-high-contrast hgroup,
html.a11y-high-contrast html,
html.a11y-high-contrast i,
html.a11y-high-contrast iframe,
html.a11y-high-contrast input,
html.a11y-high-contrast ins,
html.a11y-high-contrast kbd,
html.a11y-high-contrast label,
html.a11y-high-contrast legend,
html.a11y-high-contrast li,
html.a11y-high-contrast listing,
html.a11y-high-contrast main,
html.a11y-high-contrast mark,
html.a11y-high-contrast marquee,
html.a11y-high-contrast menu,
html.a11y-high-contrast meter,
html.a11y-high-contrast multicol,
html.a11y-high-contrast nav:not(#a11y-toolbar),
html.a11y-high-contrast nobr,
html.a11y-high-contrast object,
html.a11y-high-contrast ol,
html.a11y-high-contrast option,
html.a11y-high-contrast output,
html.a11y-high-contrast p,
html.a11y-high-contrast plaintext,
html.a11y-high-contrast pre,
html.a11y-high-contrast progress,
html.a11y-high-contrast q,
html.a11y-high-contrast rb,
html.a11y-high-contrast rp,
html.a11y-high-contrast rt,
html.a11y-high-contrast ruby,
html.a11y-high-contrast s,
html.a11y-high-contrast samp,
html.a11y-high-contrast section,
html.a11y-high-contrast small,
html.a11y-high-contrast span,
html.a11y-high-contrast strike,
html.a11y-high-contrast strong,
html.a11y-high-contrast sub,
html.a11y-high-contrast summary,
html.a11y-high-contrast sup,
html.a11y-high-contrast svg,
html.a11y-high-contrast table,
html.a11y-high-contrast tbody,
html.a11y-high-contrast td,
html.a11y-high-contrast text,
html.a11y-high-contrast textarea,
html.a11y-high-contrast th,
html.a11y-high-contrast thead,
html.a11y-high-contrast time,
html.a11y-high-contrast tr,
html.a11y-high-contrast tt,
html.a11y-high-contrast u,
html.a11y-high-contrast ul,
html.a11y-high-contrast var,
html.a11y-high-contrast video,
html.a11y-high-contrast xmp {
  background-color: black !important;
}

html.a11y-high-contrast a,
html.a11y-high-contrast article,
html.a11y-high-contrast aside,
html.a11y-high-contrast basefont,
html.a11y-high-contrast bdi,
html.a11y-high-contrast big,
html.a11y-high-contrast blink,
html.a11y-high-contrast blockquote,
html.a11y-high-contrast body,
html.a11y-high-contrast button,
html.a11y-high-contrast canvas,
html.a11y-high-contrast caption,
html.a11y-high-contrast center,
html.a11y-high-contrast code,
html.a11y-high-contrast col,
html.a11y-high-contrast colgroup,
html.a11y-high-contrast command,
html.a11y-high-contrast dd,
html.a11y-high-contrast del,
html.a11y-high-contrast details,
html.a11y-high-contrast dir,
html.a11y-high-contrast div,
html.a11y-high-contrast dl,
html.a11y-high-contrast dt,
html.a11y-high-contrast embed,
html.a11y-high-contrast fieldset,
html.a11y-high-contrast figcaption,
html.a11y-high-contrast figure,
html.a11y-high-contrast font,
html.a11y-high-contrast footer,
html.a11y-high-contrast form,
html.a11y-high-contrast header,
html.a11y-high-contrast hgroup,
html.a11y-high-contrast html,
html.a11y-high-contrast iframe,
html.a11y-high-contrast img,
html.a11y-high-contrast input,
html.a11y-high-contrast ins,
html.a11y-high-contrast kbd,
html.a11y-high-contrast label,
html.a11y-high-contrast legend,
html.a11y-high-contrast li,
html.a11y-high-contrast listing,
html.a11y-high-contrast main,
html.a11y-high-contrast mark,
html.a11y-high-contrast marquee,
html.a11y-high-contrast menu,
html.a11y-high-contrast meter,
html.a11y-high-contrast multicol,
html.a11y-high-contrast nav,
html.a11y-high-contrast nobr,
html.a11y-high-contrast object,
html.a11y-high-contrast ol,
html.a11y-high-contrast option,
html.a11y-high-contrast output,
html.a11y-high-contrast p,
html.a11y-high-contrast plaintext,
html.a11y-high-contrast pre,
html.a11y-high-contrast progress,
html.a11y-high-contrast q,
html.a11y-high-contrast rb,
html.a11y-high-contrast rp,
html.a11y-high-contrast rt,
html.a11y-high-contrast ruby,
html.a11y-high-contrast s,
html.a11y-high-contrast samp,
html.a11y-high-contrast section,
html.a11y-high-contrast select,
html.a11y-high-contrast small,
html.a11y-high-contrast span,
html.a11y-high-contrast strike,
html.a11y-high-contrast sub,
html.a11y-high-contrast summary,
html.a11y-high-contrast sup,
html.a11y-high-contrast svg,
html.a11y-high-contrast table,
html.a11y-high-contrast tbody,
html.a11y-high-contrast td,
html.a11y-high-contrast text,
html.a11y-high-contrast textarea,
html.a11y-high-contrast th,
html.a11y-high-contrast thead,
html.a11y-high-contrast time,
html.a11y-high-contrast tr,
html.a11y-high-contrast tt,
html.a11y-high-contrast ul,
html.a11y-high-contrast var,
html.a11y-high-contrast video,
html.a11y-high-contrast xmp {
  color: white !important;
}

html.a11y-high-contrast abbr,
html.a11y-high-contrast acronym,
html.a11y-high-contrast b,
html.a11y-high-contrast b span,
html.a11y-high-contrast h1 b,
html.a11y-high-contrast h1 strong,
html.a11y-high-contrast h2 b,
html.a11y-high-contrast h2 strong,
html.a11y-high-contrast h3 b,
html.a11y-high-contrast h3 strong,
html.a11y-high-contrast h4 b,
html.a11y-high-contrast h4 strong,
html.a11y-high-contrast h5 b,
html.a11y-high-contrast h5 strong,
html.a11y-high-contrast h6 b,
html.a11y-high-contrast h6 strong,
html.a11y-high-contrast strong,
html.a11y-high-contrast strong span {
  color: yellow !important;
}

html.a11y-high-contrast address,
html.a11y-high-contrast address span,
html.a11y-high-contrast cite,
html.a11y-high-contrast cite span,
html.a11y-high-contrast dfn,
html.a11y-high-contrast dfn span,
html.a11y-high-contrast em,
html.a11y-high-contrast em span,
html.a11y-high-contrast h1 em,
html.a11y-high-contrast h1 i,
html.a11y-high-contrast h2 em,
html.a11y-high-contrast h2 i,
html.a11y-high-contrast h3 em,
html.a11y-high-contrast h3 i,
html.a11y-high-contrast h4 em,
html.a11y-high-contrast h4 i,
html.a11y-high-contrast h5 em,
html.a11y-high-contrast h5 i,
html.a11y-high-contrast h6 em,
html.a11y-high-contrast h6 i,
html.a11y-high-contrast i,
html.a11y-high-contrast i span,
html.a11y-high-contrast u,
html.a11y-high-contrast u span {
  color: #ffff80 !important;
}

html.a11y-high-contrast dt {
  border-top: thin solid grey !important;
}

html.a11y-high-contrast h1,
html.a11y-high-contrast h1 a,
html.a11y-high-contrast h1 a b,
html.a11y-high-contrast h1 abbr,
html.a11y-high-contrast h1 center,
html.a11y-high-contrast h1 span,
html.a11y-high-contrast h2,
html.a11y-high-contrast h2 a,
html.a11y-high-contrast h2 a b,
html.a11y-high-contrast h2 abbr,
html.a11y-high-contrast h2 center,
html.a11y-high-contrast h2 span,
html.a11y-high-contrast h3,
html.a11y-high-contrast h3 a,
html.a11y-high-contrast h3 a b,
html.a11y-high-contrast h3 abbr,
html.a11y-high-contrast h3 center,
html.a11y-high-contrast h3 span,
html.a11y-high-contrast h4,
html.a11y-high-contrast h4 a,
html.a11y-high-contrast h4 a b,
html.a11y-high-contrast h4 abbr,
html.a11y-high-contrast h4 center,
html.a11y-high-contrast h4 span,
html.a11y-high-contrast h5,
html.a11y-high-contrast h5 a,
html.a11y-high-contrast h5 a b,
html.a11y-high-contrast h5 abbr,
html.a11y-high-contrast h5 center,
html.a11y-high-contrast h5 span,
html.a11y-high-contrast h6,
html.a11y-high-contrast h6 a,
html.a11y-high-contrast h6 a b,
html.a11y-high-contrast h6 abbr,
html.a11y-high-contrast h6 center,
html.a11y-high-contrast h6 span {
  color: #40c090 !important;
}

html.a11y-high-contrast img {
  background-color: #808080 !important;
}

html.a11y-high-contrast abbr,
html.a11y-high-contrast acronym {
  border-bottom: 1px dotted !important;
}

html.a11y-high-contrast :focus {
  outline: thin dotted !important;
}

html.a11y-high-contrast a.button,
html.a11y-high-contrast a.button abbr,
html.a11y-high-contrast a.button acronym,
html.a11y-high-contrast a.button b,
html.a11y-high-contrast a.button basefont,
html.a11y-high-contrast a.button big,
html.a11y-high-contrast a.button br,
html.a11y-high-contrast a.button code,
html.a11y-high-contrast a.button div,
html.a11y-high-contrast a.button em,
html.a11y-high-contrast a.button font,
html.a11y-high-contrast a.button h1,
html.a11y-high-contrast a.button h2,
html.a11y-high-contrast a.button h3,
html.a11y-high-contrast a.button h4,
html.a11y-high-contrast a.button h5,
html.a11y-high-contrast a.button h6,
html.a11y-high-contrast a.button i,
html.a11y-high-contrast a.button kbd,
html.a11y-high-contrast a.button rb,
html.a11y-high-contrast a.button rp,
html.a11y-high-contrast a.button rt,
html.a11y-high-contrast a.button ruby,
html.a11y-high-contrast a.button samp,
html.a11y-high-contrast a.button small,
html.a11y-high-contrast a.button span,
html.a11y-high-contrast a.button strong,
html.a11y-high-contrast a.button tt,
html.a11y-high-contrast a.button u,
html.a11y-high-contrast a.button var,
html.a11y-high-contrast a:link,
html.a11y-high-contrast a:link abbr,
html.a11y-high-contrast a:link acronym,
html.a11y-high-contrast a:link b,
html.a11y-high-contrast a:link basefont,
html.a11y-high-contrast a:link big,
html.a11y-high-contrast a:link br,
html.a11y-high-contrast a:link code,
html.a11y-high-contrast a:link div,
html.a11y-high-contrast a:link em,
html.a11y-high-contrast a:link font,
html.a11y-high-contrast a:link h1,
html.a11y-high-contrast a:link h2,
html.a11y-high-contrast a:link h3,
html.a11y-high-contrast a:link h4,
html.a11y-high-contrast a:link h5,
html.a11y-high-contrast a:link h6,
html.a11y-high-contrast a:link i,
html.a11y-high-contrast a:link kbd,
html.a11y-high-contrast a:link rb,
html.a11y-high-contrast a:link rp,
html.a11y-high-contrast a:link rt,
html.a11y-high-contrast a:link ruby,
html.a11y-high-contrast a:link samp,
html.a11y-high-contrast a:link small,
html.a11y-high-contrast a:link span,
html.a11y-high-contrast a:link strong,
html.a11y-high-contrast a:link tt,
html.a11y-high-contrast a:link u,
html.a11y-high-contrast a:link var,
html.a11y-high-contrast div#secondaryNav div#documentNavigation ul.navigationTabs li.tabItem {
  color: #0080ff !important;
}

html.a11y-high-contrast button,
html.a11y-high-contrast input,
html.a11y-high-contrast textarea,
html.a11y-high-contrast select,
html.a11y-high-contrast table,
html.a11y-high-contrast td,
html.a11y-high-contrast th,
html.a11y-high-contrast tr,
html.a11y-high-contrast tt {
  border: 1px solid #ffffff !important;
}

html.a11y-high-contrast button {
  background-color: #600040 !important;
}

html.a11y-high-contrast select {
  -webkit-appearance: listbox !important;
  background-color: #600060 !important;
}

html.a11y-high-contrast a:visited,
html.a11y-high-contrast a:visited abbr,
html.a11y-high-contrast a:visited acronym,
html.a11y-high-contrast a:visited b,
html.a11y-high-contrast a:visited basefont,
html.a11y-high-contrast a:visited big,
html.a11y-high-contrast a:visited br,
html.a11y-high-contrast a:visited code,
html.a11y-high-contrast a:visited div,
html.a11y-high-contrast a:visited em,
html.a11y-high-contrast a:visited font,
html.a11y-high-contrast a:visited h1,
html.a11y-high-contrast a:visited h2,
html.a11y-high-contrast a:visited h3,
html.a11y-high-contrast a:visited h4,
html.a11y-high-contrast a:visited h5,
html.a11y-high-contrast a:visited h6,
html.a11y-high-contrast a:visited i,
html.a11y-high-contrast a:visited kbd,
html.a11y-high-contrast a:visited rb,
html.a11y-high-contrast a:visited rp,
html.a11y-high-contrast a:visited rt,
html.a11y-high-contrast a:visited ruby,
html.a11y-high-contrast a:visited samp,
html.a11y-high-contrast a:visited small,
html.a11y-high-contrast a:visited span,
html.a11y-high-contrast a:visited strong,
html.a11y-high-contrast a:visited tt,
html.a11y-high-contrast a:visited u,
html.a11y-high-contrast a:visited var,
html.a11y-high-contrast div#secondaryNav div#documentNavigation ul.navigationTabs li.tabItem.active {
  color: #00ffff !important;
}

html.a11y-high-contrast ::selection,
html.a11y-high-contrast ::-moz-selection {
  background-color: #4080c0 !important;
}

html.a11y-high-contrast a.button:hover,
html.a11y-high-contrast a.button:hover abbr,
html.a11y-high-contrast a.button:hover acronym,
html.a11y-high-contrast a.button:hover b,
html.a11y-high-contrast a.button:hover basefont,
html.a11y-high-contrast a.button:hover big,
html.a11y-high-contrast a.button:hover br,
html.a11y-high-contrast a.button:hover code,
html.a11y-high-contrast a.button:hover div,
html.a11y-high-contrast a.button:hover em,
html.a11y-high-contrast a.button:hover font,
html.a11y-high-contrast a.button:hover h1,
html.a11y-high-contrast a.button:hover h2,
html.a11y-high-contrast a.button:hover h3,
html.a11y-high-contrast a.button:hover h4,
html.a11y-high-contrast a.button:hover h5,
html.a11y-high-contrast a.button:hover h6,
html.a11y-high-contrast a.button:hover i,
html.a11y-high-contrast a.button:hover kbd,
html.a11y-high-contrast a.button:hover rb,
html.a11y-high-contrast a.button:hover rp,
html.a11y-high-contrast a.button:hover rt,
html.a11y-high-contrast a.button:hover ruby,
html.a11y-high-contrast a.button:hover samp,
html.a11y-high-contrast a.button:hover small,
html.a11y-high-contrast a.button:hover span,
html.a11y-high-contrast a.button:hover strong,
html.a11y-high-contrast a.button:hover tt,
html.a11y-high-contrast a.button:hover u,
html.a11y-high-contrast a.button:hover var,
html.a11y-high-contrast a:link:hover,
html.a11y-high-contrast a:link:hover abbr,
html.a11y-high-contrast a:link:hover acronym,
html.a11y-high-contrast a:link:hover b,
html.a11y-high-contrast a:link:hover basefont,
html.a11y-high-contrast a:link:hover big,
html.a11y-high-contrast a:link:hover br,
html.a11y-high-contrast a:link:hover code,
html.a11y-high-contrast a:link:hover div:not(.background-overlay):not(.bg-image),
html.a11y-high-contrast a:link:hover em,
html.a11y-high-contrast a:link:hover font,
html.a11y-high-contrast a:link:hover h1,
html.a11y-high-contrast a:link:hover h2,
html.a11y-high-contrast a:link:hover h3,
html.a11y-high-contrast a:link:hover h4,
html.a11y-high-contrast a:link:hover h5,
html.a11y-high-contrast a:link:hover h6,
html.a11y-high-contrast a:link:hover i,
html.a11y-high-contrast a:link:hover kbd,
html.a11y-high-contrast a:link:hover rb,
html.a11y-high-contrast a:link:hover rp,
html.a11y-high-contrast a:link:hover rt,
html.a11y-high-contrast a:link:hover ruby,
html.a11y-high-contrast a:link:hover samp,
html.a11y-high-contrast a:link:hover small,
html.a11y-high-contrast a:link:hover span,
html.a11y-high-contrast a:link:hover strong,
html.a11y-high-contrast a:link:hover tt,
html.a11y-high-contrast a:link:hover u,
html.a11y-high-contrast a:link:hover var,
html.a11y-high-contrast a:visited:hover,
html.a11y-high-contrast a:visited:hover abbr,
html.a11y-high-contrast a:visited:hover acronym,
html.a11y-high-contrast a:visited:hover b,
html.a11y-high-contrast a:visited:hover basefont,
html.a11y-high-contrast a:visited:hover big,
html.a11y-high-contrast a:visited:hover br,
html.a11y-high-contrast a:visited:hover code,
html.a11y-high-contrast a:visited:hover div:not(.article-top-image):not(.background-overlay):not(.bg-image),
html.a11y-high-contrast a:visited:hover em,
html.a11y-high-contrast a:visited:hover font,
html.a11y-high-contrast a:visited:hover h1,
html.a11y-high-contrast a:visited:hover h2,
html.a11y-high-contrast a:visited:hover h3,
html.a11y-high-contrast a:visited:hover h4,
html.a11y-high-contrast a:visited:hover h5,
html.a11y-high-contrast a:visited:hover h6,
html.a11y-high-contrast a:visited:hover i,
html.a11y-high-contrast a:visited:hover kbd,
html.a11y-high-contrast a:visited:hover rb,
html.a11y-high-contrast a:visited:hover rp,
html.a11y-high-contrast a:visited:hover rt,
html.a11y-high-contrast a:visited:hover ruby,
html.a11y-high-contrast a:visited:hover samp,
html.a11y-high-contrast a:visited:hover small,
html.a11y-high-contrast a:visited:hover span,
html.a11y-high-contrast a:visited:hover strong,
html.a11y-high-contrast a:visited:hover tt,
html.a11y-high-contrast a:visited:hover u,
html.a11y-high-contrast a:visited:hover var {
  background-color: #400000 !important;
}

html.a11y-high-contrast body > input#site + div#wrapper span.mk,
html.a11y-high-contrast body > input#site + div#wrapper span.mk b,
html.a11y-high-contrast input[type=reset] {
  background-color: #400060 !important;
}

html.a11y-high-contrast div[role="button"],
html.a11y-high-contrast input[type=button],
html.a11y-high-contrast input[type=submit] {
  background-color: #600040 !important;
}

html.a11y-high-contrast input[type=search] {
  -webkit-appearance: textfield !important;
}

html.a11y-high-contrast html button[disabled],
html.a11y-high-contrast html input[disabled],
html.a11y-high-contrast html select[disabled],
html.a11y-high-contrast html textarea[disabled] {
  background-color: #404040 !important;
}

html.a11y-high-contrast .menu li a span.label {
  text-transform: none !important;
}

html.a11y-high-contrast .menu li a span.label,
html.a11y-high-contrast div.jwplayer span.jwcontrolbar,
html.a11y-high-contrast div.jwplayer span.jwcontrols {
  display: inline !important;
}

html.a11y-high-contrast a:link.new,
html.a11y-high-contrast a:link.new i,
html.a11y-high-contrast a:link.new b,
html.a11y-high-contrast span.Apple-style-span {
  color: #ffff40 !important;
}

html.a11y-high-contrast html.mediawiki img.tex {
  background-color: white !important;
  border: white solid 3px !important;
}

html.a11y-high-contrast text > tspan:first-letter,
html.a11y-high-contrast text > tspan:first-line {
  background-color: inherit !important;
  color: inherit !important;
}

html.a11y-high-contrast div.sbtc div.sbsb_a li.sbsb_d div,
html.a11y-high-contrast table.gssb_c tr.gssb_i a,
html.a11y-high-contrast table.gssb_c tr.gssb_i b,
html.a11y-high-contrast table.gssb_c tr.gssb_i span,
html.a11y-high-contrast table.gssb_c tr.gssb_i td {
  background-color: #003050 !important;
}

html.a11y-high-contrast img[width="18"][height="18"] {
  height: 18px !important;
  width: 18px !important;
}

html.a11y-high-contrast a > span.iconHelp:empty:after {
  content: "Help" !important;
}

html.a11y-high-contrast div#gmap,
html.a11y-high-contrast div#gmap * {
  background: initial !important;
}

html.a11y-high-contrast .swiper-container .swiper-slide div {
  background-color: transparent !important;
}
